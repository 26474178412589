<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Diagnose - Wmo</h1>
        <div class="row">
          <p class="intro-section-text">
            <b>Toelichting:</b> Onderstaand dashboard geeft informatie over de Wmo (wet maatschappelijke ondersteuning)
            als onderdeel van het levenspad Diagnose. Het geeft inzicht in het gebruik van langdurige zorg gefinancierd
            vanuit de Wmo door mensen met dementie. Specifiek wordt in dit dashboard als onderdeel van de levenspadfase
            diagnose gefocust op informatie over de Wmo.
          </p>
          <p class="intro-section-text">
            <b>Populatie:</b> De populatie die gebruikt wordt voor de inzichten met betrekking tot de Wmo-voorzieningen
            zijn bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en
            toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er
            geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. Van deze
            populatie wordt in onderstaand dashboard het percentage weergegeven dat gebruik maakt van de beschreven
            voorziening.
          </p>
        </div>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Gebruik Wmo voorziening in ${selectedYear}`"
          :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat enige vorm van Wmo-zorg ontvangt.'"
          :x="zorgkantoorregionaam_wmo" :y="perc_wmo_table.map(value => `${value}%`)" :xtitle="xtitle" :ytitle="ytitle"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Gebruik Wmo voorziening in {{ selectedYear }}</template>
          <div slot="card-body">
            <p class="graphtext">Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat
              gebruik maakt van minimaal één Wmo-gefinancierde voorziening. Er is geselecteerd op mensen met dementie die
              het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn.</p>
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met Wmo gebruik'"
              :names="['%Gebruikt Wmo voorziening']" :x="[zorgkantoorregionaam_wmo]" :y="[perc_wmo]" :showlegend="false"
              :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>
    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Gebruik ondersteuning thuis in ${selectedYear}`"
          :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo-gefinancierde ondersteuning thuis ontvangt.'"
          :x="zorgkantoorregionaam_ot" :y="perc_ondersteuning_thuis_table.map(value => `${value}%`)" :xtitle="xtitle"
          :ytitle="ytitle" :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Gebruik ondersteuning thuis in {{ selectedYear }}</template>
          <div slot="card-body">
            <p class="graphtext">Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat
              gebruik maakt van Wmo-gefinancierde ondersteuning thuis. Ondersteuning thuis omvat persoonlijke verzorging,
              begeleiding, kortdurend verblijf, dagbesteding en andere groepsgerichte ondersteuning. </p>
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met ondersteuning thuis'"
              :names="['%Gebruikt ondersteuning thuis']" :x="[zorgkantoorregionaam_ot]" :y="[perc_ondersteuning_thuis]"
              :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>


    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Gebruik hulp bij huishouden in ${selectedYear}`"
          :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo-gefinancierde hulp bij het huishouden ontvangt.'"
          :x="zorgkantoorregionaam_hh" :y="perc_hulphuis_table.map(value => `${value}%`)" :xtitle="xtitle"
          :ytitle="ytitle" :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Gebruik hulp bij huishouden in {{ selectedYear }}</template>
          <div slot="card-body">
            <p class="graphtext"> Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie
              dat gebruik maakt van Wmo-gefinancierde hulp bij huishouden. Hulp bij huishouden omvat alle ondersteuning
              bij het huishouden. </p>
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met hulp bij huishouden'"
              :names="['%Gebruikt hulp huishouden']" :x="[zorgkantoorregionaam_hh]" :y="[perc_hulphuis]"
              :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>
    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Gebruik hulpmiddelen en diensten in ${selectedYear}`"
          :hovertext="'De populatie bestaat uit bewoners bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo-gefinancierde hulpmiddelen of diensten ontvangt.'"
          :x="zorgkantoorregionaam_hd" :y="perc_hulpdienst_table.map(value => `${value}%`)" :xtitle="xtitle"
          :ytitle="ytitle" :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Gebruik hulpmiddelen en diensten in {{ selectedYear }}</template>
          <div slot="card-body">
            <p class="graphtext">Onderstaande grafiek geeft inzicht in het percentage thuiswonende mensen met dementie dat
              gebruik maakt van Wmo-gefinancierde hulpmiddelen en diensten. Als onderdeel van hulpmiddelen en diensten
              worden alle woon- en vervoersdiensten en voorzieningen, rolstoelen en overige hulpmiddelen meegenomen in het
              overzicht.</p>
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met hulpmiddelen en diensten'"
              :names="['%Gebruikt hulpmiddelen en diensten']" :x="[zorgkantoorregionaam_hd]" :y="[perc_hulpdienst]"
              :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card
          :cardId="`Type maatschappelijke ondersteuning, totale populatie mensen met dementie vs algemene bevolking in ${selectedYear}`"
          :hovertext="'De populatie `thuiswonende mensen met dementie` bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. Informatie over de algemene bevolking komt uit https://opendata.cbs.nl/#/CBS/nl/dataset/83262NED.'"
          :x="bevolkingsgroep" :y="ondersteuning_thuis_perc.map(value => `${value}%`)"
          :z="hulp_bij_huishouden_perc.map(value => `${value}%`)"
          :w="hulpmiddelen_diensten_perc.map(value => `${value}%`)" :xtitle="'Groep'" :ytitle="'Ondersteuning thuis'"
          :ztitle="'Hulp bij Huishouden'" :wtitle="'Hulpmiddelen en Diensten'" :tableHeight='300'>
          <template slot="card-header">Type maatschappelijke ondersteuning, totale populatie mensen met dementie vs
            algemene bevolking in {{ selectedYear }}</template>
          <div slot="card-body" v-if="selectedYear !== 2021">
            Onderstaande grafiek maakt inzichtelijk hoeveel procent van een bepaalde bevolkingsgroep gebruik maakt van
            Wmo-gefinancierde ondersteuning thuis, hulp bij huishouden en of hulpmiddelen en diensten. Deze percentages
            worden gegeven voor de groep mensen met dementie, mensen van 60 jaar en ouder in de algemene bevolking en voor
            de mensen ouder dan 85 in de algemene bevolking.
            <grouped-bar-chart v-if="load.graphTwo" :ytitle="'% met maatschappelijke ondersteuning'" :tickformat="',.0%'"
              :height="500" :names="['Ondersteuning thuis', 'Hulp bij Huishouden', 'Hulpmiddelen en Diensten']"
              :x="[bevolkingsgroep, bevolkingsgroep, bevolkingsgroep]"
              :y="[ondersteuning_thuis, hulp_bij_huishouden, hulpmiddelen_diensten]" />
          </div>
          <div slot="card-body" v-if="selectedYear === 2021">
            Deze grafiek is nog niet beschikbaar voor <em><b class="blue">2021</b></em>.
          </div>
        </card>
      </div>
    </div>
    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Maatschapppelijke ondersteuning vanuit de Wmo per leeftijdsgroep in ${selectedYear}`"
          :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft de leeftijdsverdeling van deze mensen weer. Wanneer er groepen voor een zorgkantoor of Dementienetwerk missen in de grafiek, betekent dit dat er een categorie is met minder dan 10 personen. Op dat moment wordt ook een andere categorie niet zichtbaar zodat de data niet herleidbaar is naar een kleine groep mensen met dementie.'"
          :x="wmoleeftijd_groep" :y="zorgkantoorregionaam_alle" :z="percentage_alle.map(value => `${value}%`)"
          :xtitle="'Groep'" :ytitle="'Regio naam'" :ztitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 6300 : 3150">
          <template slot="card-header">Maatschapppelijke ondersteuning vanuit de Wmo per leeftijdsgroep in
            {{ selectedYear }}</template>
          <div slot="card-body">
            <p class="graphtext"> In deze grafiek wordt de leeftijdsverdeling zichtbaar gemaakt voor alle
              zorgkantoorregio's en Dementienetwerken. Ook wordt de verdeling op landelijk niveau getoond. </p>
            <stacked-bar-chart-colors v-if="load.graphThree" :ytitle="'% in leeftijdsgroep'"
              :names="['< 65 jaar', '66 - 79 jaar', '80 - 89 jaar', '90 jaar en ouder']"
              :x="[zorgkantoorregionaam_65, zorgkantoorregionaam_66, zorgkantoorregionaam_80, zorgkantoorregionaam_90]"
              :y="[leeftijd_65, leeftijd_66, leeftijd_80, leeftijd_90]" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Gebruik Wmo voorziening'"
          :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat enige vorm van Wmo-zorg ontvangt.'"
          :x="ondersteuning_jaar" :y="perc_wmo_table.map(value => `${value}%`)" :xtitle="'Jaar'" :ytitle="'Percentage'"
          :tableHeight='300'>
          <template slot="card-header">Gebruik Wmo voorziening</template>
          <div slot="card-body">
            Onderstaande grafiek geeft inzicht in het landelijk niveau van het percentage thuiswonende mensen met dementie
            dat gebruik maakt van minimaal één Wmo-voorziening. Er is een selectie gemaakt voor mensen met dementie die
            het hele jaar door geen gebruik maken van de Wlz-zorg en thuiswonen.
            <stacked-bar-chart-colors v-if="load.graphFour" :isLandelijk="false" :ytitle="'% met Wmo gebruik'"
              :xtitle="'Jaar'" :tickangle='0' :x="[ondersteuning_jaar]" :y="[perc_wmo]" :showlegend="false"
              :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Gebruik ondersteuning thuis'"
          :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo-gefinancierde ondersteuning thuis ontvangt.'"
          :x="ondersteuning_jaar" :z="perc_ondersteuning_thuis_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight='300'>
          <template slot="card-header">Gebruik ondersteuning thuis</template>
          <div slot="card-body">
            Onderstaande grafiek geeft inzicht in het landelijke percentage thuiswonende mensen met dementie dat gebruik
            maakt van Wmo-steun aan huis. Ondersteuning aan huis omvat persoonlijke verzorging, begeleiding, kortdurend
            verblijf, dagbesteding en andere groepsgerichte ondersteuning.
            <stacked-bar-chart-colors v-if="load.graphFour" :isLandelijk="false" :ytitle="'% met ondersteuning thuis'"
              :xtitle="'Jaar'" :tickangle='0' :x="[ondersteuning_jaar]" :y="[perc_ondersteuning_thuis]"
              :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Gebruik hulp bij huishouden'"
          :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo-gefinancierde hulp bij het huishouden ontvangt.'"
          :x="ondersteuning_jaar" :y="perc_hulphuis_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight='300'>
          <template slot="card-header">Gebruik hulp bij huishouden</template>
          <div slot="card-body">
            Onderstaande grafiek geeft inzicht in de landelijke hoogte van het percentage thuiswonende mensen met dementie
            dat gebruik maakt van huishoudelijke hulp uit de Wmo. Huishoudelijke hulp omvat alle ondersteuning bij
            huishoudelijk werk.
            <stacked-bar-chart-colors v-if="load.graphFour" :isLandelijk="false" :ytitle="'% met hulp bij huishouden'"
              :xtitle="'Jaar'" :tickangle='0' :x="[ondersteuning_jaar]" :y="[perc_hulphuis]" :showlegend="false"
              :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Gebruik hulpmiddelen en diensten'"
          :hovertext="'De populatie bestaat uit bewoners bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft het percentage van de zojuist beschreven populatie weer dat Wmo-gefinancierde hulpmiddelen of diensten ontvangt.'"
          :x="ondersteuning_jaar" :y="perc_hulpdienst_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight='300'>
          <template slot="card-header">Gebruik hulpmiddelen en diensten</template>
          <div slot="card-body">
            Onderstaande grafiek geeft inzicht in het landelijk niveau van het percentage thuiswonende mensen met dementie
            dat gebruik maakt van Wmo-middelen en -diensten. Als onderdeel van hulpmiddelen en diensten zijn alle woon- en
            vervoersdiensten en -voorzieningen, rolstoelen en andere hulpmiddelen in het overzicht opgenomen.
            <stacked-bar-chart-colors v-if="load.graphFour" :isLandelijk="false" :ytitle="'% met Wmo gebruik'"
              :xtitle="'Jaar'" :tickangle='0' :x="[ondersteuning_jaar]" :y="[perc_hulpdienst_land]" :showlegend="false"
              :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Maatschapppelijke ondersteuning vanuit de Wmo per leeftijdsgroep'"
          :hovertext="'De populatie bestaat uit bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Daarnaast is er geselecteerd op mensen met dementie die het hele jaar geen Wlz-zorg gebruiken en thuiswonend zijn. De grafiek geeft de leeftijdsverdeling van deze mensen weer. Wanneer er groepen voor een zorgkantoor of Dementienetwerk missen in de grafiek, betekent dit dat er een categorie is met minder dan 10 personen. Op dat moment wordt ook een andere categorie niet zichtbaar zodat de data niet herleidbaar is naar een kleine groep mensen met dementie.'"
          :x="jaar_groep" :y="wmoleeftijd_groep" :z="percentage_alle.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Leeftijd_groep'" :ztitle="'Percentage'" :tableHeight='600'>
          <template slot="card-header">Maatschapppelijke ondersteuning vanuit de Wmo per leeftijdsgroep</template>
          <div slot="card-body">
            Deze grafiek toont het landelijke niveau van de leeftijdsverdeling voor alle zorgkantoorregio’s en
            dementienetwerken.
            <stacked-bar-chart-colors-multi class="custom-stacked-bar-chart" v-if="load.graphFive" :tickangle='0'
              :isLandelijk='false' :height="360" :name4="'<65'" :x4="zorgkantoorregionaam_65" :xx4="jaar_landel_65"
              :y4="leeftijd_65" :name3="'65-79'" :x3="zorgkantoorregionaam_66" :xx3="jaar_landel_66" :y3="leeftijd_66"
              :name2="'80-89'" :x2="zorgkantoorregionaam_80" :xx2="jaar_landel_80" :y2="leeftijd_80" :name1="'90+'"
              :x1="zorgkantoorregionaam_90" :xx1="jaar_landel_90" :y1="leeftijd_90" />
          </div>
        </card>
      </div>
    </div>

    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo" />
  </div>
</template>

<script>

import Card from '../components/Card'
import StackedBarChartColors from '../components/StackedBarChartColors'
import StackedBarChartColorsMulti from '../components/StackedBarChartColorsMulti'
import { mapGetters } from 'vuex'
import GroupedBarChart from '../components/GroupedBarChart'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Diagnose - Wmo',
  },
  components: { Card, StackedBarChartColors, GroupedBarChart, StackedBarChartColorsMulti, Footer },
  data() {
    return {
      xtitle: 'Regio namen',
      ytitle: 'Percentage',
      ztitle: 'Percentage Z',
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      wmoleeftijd_groep: [],
      zorgkantoorregionaam_alle: [],
      percentage_alle: [],
      zorgkantoorregionaam_65: [],
      leeftijd_65: [],
      zorgkantoorregionaam_66: [],
      leeftijd_66: [],
      zorgkantoorregionaam_80: [],
      leeftijd_80: [],
      zorgkantoorregionaam_90: [],
      leeftijd_90: [],
      wmoleeftijd_jaar: [],
      zorgkantoorregionaam_wmo: [],
      zorgkantoorregionaam_ot: [],
      zorgkantoorregionaam_hh: [],
      zorgkantoorregionaam_hd: [],
      zorgkantoorregionaam_hd_land: [],
      ondersteuning_thuis_perc: [],
      hulp_bij_huishouden_perc: [],
      hulpmiddelen_diensten_perc: [],
      jaar_landel_65: [],
      jaar_landel_66: [],
      jaar_landel_80: [],
      jaar_landel_90: [],
      perc_wmo: [],
      perc_wmo_table: [],
      perc_ondersteuning_thuis: [],
      perc_ondersteuning_thuis_table: [],
      perc_hulphuis: [],
      perc_hulpdienst: [],
      perc_hulphuis_table: [],
      perc_hulpdienst_table: [],
      perc_hulpdienst_land: [],
      perc_dagopvang: [],
      ondersteuning_jaar: [],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false,
        graphFive: false
      },
      hulp_bij_huishouden: [],
      ondersteuning_thuis: [],
      hulpmiddelen_diensten: [],
      bevolkingsgroep: [],
      jaar_groep: [],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
      this.loadBevolkingOndersteuning()
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  methods: {
    loadAllData(level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_ondersteuning/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => b.percentage_wmo - a.percentage_wmo)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
            this.perc_wmo_table = response.data.map(d => (d.percentage_wmo * 100).toFixed(1))
            this.perc_wmo = response.data.map(d => (d.percentage_wmo * 100))
            this.zorgkantoorregionaam_wmo = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_ondersteuning_thuis - a.percentage_ondersteuning_thuis)
            this.perc_ondersteuning_thuis_table = response.data.map(d => (d.percentage_ondersteuning_thuis * 100).toFixed(1))
            this.perc_ondersteuning_thuis = response.data.map(d => (d.percentage_ondersteuning_thuis * 100))
            this.zorgkantoorregionaam_ot = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_hulp_huishouden - a.percentage_hulp_huishouden)
            this.perc_hulphuis = response.data.map(d => (d.percentage_hulp_huishouden * 100))
            this.perc_hulphuis_table = response.data.map(d => (d.percentage_hulp_huishouden * 100).toFixed(1))
            this.zorgkantoorregionaam_hh = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_hulpmiddelen_diensten - a.percentage_hulpmiddelen_diensten)
            this.perc_hulpdienst = response.data.map(d => (d.percentage_hulpmiddelen_diensten * 100))
            this.perc_hulpdienst_table = response.data.map(d => (d.percentage_hulpmiddelen_diensten * 100).toFixed(1))
            this.zorgkantoorregionaam_hd = response.data.map(d => d.zorgkantoorregionaam)
            // this.perc_dagopvang = response.data.map(d => (d.percentage_dagopvang))
            this.load.graphOne = true
          }
        ),
          this.$http.get(`/api/zorgkantoor_wmoleeftijd/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a, b) => a.zorgkantoorregionaam - b.zorgkantoorregionaam)
              this.wmoleeftijd_groep = response.data.map(d => d.wmoleeftijd)
              this.zorgkantoorregionaam_alle = response.data.map(d => d.zorgkantoorregionaam)
              this.percentage_alle = response.data.map(d => (d.percentage * 100).toFixed(1))


              response.data.sort((a, b) => b.percentage - a.percentage)
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.zorgkantoorregionaam_65 = response.data.filter(x => x.wmoleeftijd == '<65').map(d => d.zorgkantoorregionaam)
              this.leeftijd_65 = response.data.filter(x => x.wmoleeftijd == '<65').map(d => (d.percentage * 100))
              this.zorgkantoorregionaam_66 = response.data.filter(x => x.wmoleeftijd == '65-79').map(d => d.zorgkantoorregionaam)
              this.leeftijd_66 = response.data.filter(x => x.wmoleeftijd == '65-79').map(d => (d.percentage * 100))
              this.zorgkantoorregionaam_80 = response.data.filter(x => x.wmoleeftijd == '80-89').map(d => d.zorgkantoorregionaam)
              this.leeftijd_80 = response.data.filter(x => x.wmoleeftijd == '80-89').map(d => (d.percentage * 100))
              this.zorgkantoorregionaam_90 = response.data.filter(x => x.wmoleeftijd == '90+').map(d => d.zorgkantoorregionaam)
              this.leeftijd_90 = response.data.filter(x => x.wmoleeftijd == '90+').map(d => (d.percentage * 100))
              this.load.graphThree = true
            }
          )
      }
      else if (level === 'landelijk') {
        this.$http.get(`/api/zorgkantoor_ondersteuning/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a, b) => a.jaar - b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
            this.perc_wmo = response.data.map(d => (d.percentage_wmo * 100))
            this.perc_wmo_table = response.data.map(d => (d.percentage_wmo * 100).toFixed(1))
            this.ondersteuning_jaar = response.data.map(d => d.jaar)
            this.zorgkantoorregionaam_wmo = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_ondersteuning_thuis - a.percentage_ondersteuning_thuis)
            this.perc_ondersteuning_thuis = response.data.map(d => (d.percentage_ondersteuning_thuis * 100))
            this.zorgkantoorregionaam_ot = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_hulp_huishouden - a.percentage_hulp_huishouden)
            this.perc_hulphuis = response.data.map(d => (d.percentage_hulp_huishouden * 100))
            this.perc_hulphuis_table = response.data.map(d => (d.percentage_hulp_huishouden * 100).toFixed(1))
            this.zorgkantoorregionaam_hh = response.data.map(d => d.zorgkantoorregionaam)
            response.data.sort((a, b) => b.percentage_hulpmiddelen_diensten - a.percentage_hulpmiddelen_diensten)
            this.perc_hulpdienst_land = response.data.map(d => (d.percentage_hulpmiddelen_diensten * 100))
            this.perc_hulpdienst_table = response.data.map(d => (d.percentage_hulpmiddelen_diensten * 100).toFixed(1))
            this.zorgkantoorregionaam_hd_land = response.data.map(d => d.zorgkantoorregionaam)
            this.load.graphFour = true
          }
        ),
          this.$http.get(`/api/zorgkantoor_wmoleeftijd/get_data/?landelijk=True`).then(
            (response) => {

              response.data.sort((a, b) => a.zorgkantoorregionaam - b.zorgkantoorregionaam)
              this.wmoleeftijd_groep = response.data.map(d => d.wmoleeftijd)
              this.jaar_groep = response.data.map(d => d.jaar)
              this.zorgkantoorregionaam_alle = response.data.map(d => d.zorgkantoorregionaam)
              this.percentage_alle = response.data.map(d => (d.percentage * 100).toFixed(1))

              response.data.sort((a, b) => a.jaar - b.jaar)
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.jaar_landel_65 = response.data.filter(x => x.wmoleeftijd == "<65").map(d => d.jaar)
              this.zorgkantoorregionaam_65 = response.data.filter(x => x.wmoleeftijd == '<65').map(d => d.zorgkantoorregionaam)
              this.leeftijd_65 = response.data.filter(x => x.wmoleeftijd == '<65').map(d => (d.percentage))
              this.jaar_landel_66 = response.data.filter(x => x.wmoleeftijd == "65-79").map(d => d.jaar)
              this.zorgkantoorregionaam_66 = response.data.filter(x => x.wmoleeftijd == '65-79').map(d => d.zorgkantoorregionaam)
              this.leeftijd_66 = response.data.filter(x => x.wmoleeftijd == '65-79').map(d => (d.percentage))
              this.jaar_landel_80 = response.data.filter(x => x.wmoleeftijd == "80-89").map(d => d.jaar)
              this.zorgkantoorregionaam_80 = response.data.filter(x => x.wmoleeftijd == '80-89').map(d => d.zorgkantoorregionaam)
              this.leeftijd_80 = response.data.filter(x => x.wmoleeftijd == '80-89').map(d => (d.percentage))
              this.jaar_landel_90 = response.data.filter(x => x.wmoleeftijd == "90+").map(d => d.jaar)
              this.zorgkantoorregionaam_90 = response.data.filter(x => x.wmoleeftijd == '90+').map(d => d.zorgkantoorregionaam)
              this.leeftijd_90 = response.data.filter(x => x.wmoleeftijd == '90+').map(d => (d.percentage))
              this.load.graphFive = true
            }
          )
      }
      else {
        this.$http.get(`/api/dementienetwerk_ondersteuning/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => b.percentage_wmo - a.percentage_wmo)
            response.data = response.data.filter(x => x.dementienetwerknaamregionaam !== 'Missing')
            this.perc_wmo = response.data.map(d => (d.percentage_wmo * 100));
            this.perc_wmo_table = response.data.map(d => (d.percentage_wmo * 100).toFixed(1))
            this.zorgkantoorregionaam_wmo = response.data.map(d => d.dementienetwerknaam)
            response.data.sort((a, b) => b.percentage_ondersteuning_thuis - a.percentage_ondersteuning_thuis)
            this.perc_ondersteuning_thuis = response.data.map(d => (d.percentage_ondersteuning_thuis * 100))
            this.perc_ondersteuning_thuis_table = response.data.map(d => (d.percentage_ondersteuning_thuis * 100).toFixed(1))
            this.zorgkantoorregionaam_ot = response.data.map(d => d.dementienetwerknaam)
            response.data.sort((a, b) => b.percentage_hulp_huishouden - a.percentage_hulp_huishouden)
            this.perc_hulphuis = response.data.map(d => (d.percentage_hulp_huishouden * 100))
            this.perc_hulphuis_table = response.data.map(d => (d.percentage_hulp_huishouden * 100).toFixed(1))
            this.zorgkantoorregionaam_hh = response.data.map(d => d.dementienetwerknaam)
            response.data.sort((a, b) => b.percentage_hulpmiddelen_diensten - a.percentage_hulpmiddelen_diensten)
            this.perc_hulpdienst = response.data.map(d => (d.percentage_hulpmiddelen_diensten * 100))
            this.perc_hulpdienst_table = response.data.map(d => (d.percentage_hulpmiddelen_diensten * 100).toFixed(1))
            // this.perc_dagopvang = response.data.map(d => (d.percentage_dagopvang))
            this.zorgkantoorregionaam_hd = response.data.map(d => d.dementienetwerknaam)
            this.load.graphOne = true
          }
        ),
          this.$http.get(`/api/dementienetwerk_wmoleeftijd/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a, b) => a.dementienetwerknaam - b.dementienetwerknaam)
              this.wmoleeftijd_groep = response.data.map(d => d.wmoleeftijd)
              this.zorgkantoorregionaam_alle = response.data.map(d => d.dementienetwerknaam)
              this.percentage_alle = response.data.map(d => (d.percentage * 100).toFixed(1))

              response.data.sort((a, b) => b.percentage - a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !== 'Landelijk')
              this.zorgkantoorregionaam_65 = response.data.filter(x => x.wmoleeftijd == '<65').map(d => d.dementienetwerknaam)
              this.leeftijd_65 = response.data.filter(x => x.wmoleeftijd == '<65').map(d => (d.percentage * 100))
              this.zorgkantoorregionaam_66 = response.data.filter(x => x.wmoleeftijd == '65-79').map(d => d.dementienetwerknaam)
              this.leeftijd_66 = response.data.filter(x => x.wmoleeftijd == '65-79').map(d => (d.percentage * 100))
              this.zorgkantoorregionaam_80 = response.data.filter(x => x.wmoleeftijd == '80-89').map(d => d.dementienetwerknaam)
              this.leeftijd_80 = response.data.filter(x => x.wmoleeftijd == '80-89').map(d => (d.percentage * 100))
              this.zorgkantoorregionaam_90 = response.data.filter(x => x.wmoleeftijd == '90+').map(d => d.dementienetwerknaam)
              this.leeftijd_90 = response.data.filter(x => x.wmoleeftijd == '90+').map(d => (d.percentage * 100))
              this.load.graphThree = true
            }
          )
      }
    },
    loadBevolkingOndersteuning() {
      this.$http.get(`/api/bevolking_ondersteuning/get_data/?jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.bevolkingsgroep = response.data.map(d => d.groep)
          this.ondersteuning_thuis = response.data.map(d => (d.percentage_ondersteuning_thuis))
          this.hulp_bij_huishouden = response.data.map(d => (d.percentage_hulp_huishouden))
          this.hulpmiddelen_diensten = response.data.map(d => (d.percentage_hulpmiddelen_diensten))

          this.ondersteuning_thuis_perc = response.data.map(d => (d.percentage_ondersteuning_thuis * 100).toFixed(1))
          this.hulp_bij_huishouden_perc = response.data.map(d => (d.percentage_hulp_huishouden * 100).toFixed(1))
          this.hulpmiddelen_diensten_perc = response.data.map(d => (d.percentage_hulpmiddelen_diensten * 100).toFixed(1))
          this.load.graphTwo = true
        }
      )
    }
  },
  mounted() {
    this.loadAllData(this.selectedLevel),
      this.loadBevolkingOndersteuning()
  },
  computed: {
    ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  }
}
</script>

<style>
.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}

.title {
  margin: 1rem 0 1rem 0;
  font-style: normal;
  font-weight: bold;
  color: var(--color-1);
}

.card-text {
  margin: 1rem 0 1rem 0;
  font-style: normal;
  color: var(--color-10);
  font-size: 15px;
}

.custom-space {
  margin: 1rem 0 1rem 0;
}

.custom-stacked-bar-chart text {
  font-size: 16px;
  /* Adjust the font size as needed */
}

.custom-dropdown {
  background-color: transparent;
  color: var(--color-1);
  font-weight: bold;
}

.custom-control {
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
  font-weight: bold;
}

.custom-control:focus {
  outline: none !important;

}

.max-min-maptext {
  font-size: 2em;
  font-style: normal;
  color: var(--color-1);
  letter-spacing: 1px;
  font-weight: bold;
  display: inline;
}

.landelijk-maptext {
  font-style: normal;
  font-size: 2em;
  color: var(--color-11);
  letter-spacing: 1px;
  font-weight: bold;
  display: inline;
}

.p-map {
  font-style: normal;
  display: inline;
  letter-spacing: 1px;
  color: var(--color-1);
}

.p-landelijk {
  font-style: normal;
  display: inline;
  letter-spacing: 1px;
  color: var(--color-11);
}

.hr-maptext {
  display: block;
  height: 50px;
  width: 100px;
  background-color: black;
  margin-right: 10px;
}


.b-progress-success {
  background-color: var(--color-11);
}


.custom-button {
  text-align: left;
  padding-left: 6px;
  color: var(--color-1);
  background: var(--color-9);
  border: none;
  outline: none;
}

.custom-button:hover {
  text-align: left;
  padding-left: 6px;
  color: var(--color-2);
  background: var(--color-1);
  border: none;
  outline: none;
}

.bg-special {
  background-color: var(--color-1) !important;
  font-weight: bold;
}

.intro-section-colour-block {
  width: 100%;
  position: relative;
}

.intro-section-title {
  font-weight: bold;
  color: #1f2a44;
  margin-bottom: 2rem;
}

.intro-section-text {
  color: #1f2a44;
}
</style>
